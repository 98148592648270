import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { useHistory } from 'react-router';
import { locale } from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/en-ca';
import { app, authentication } from '@microsoft/teams-js';
import { useTranslation } from 'react-i18next';

import Page from './components/Page';
import { addAuthData, addFeatureTranslationsToStore, addUserData, enableLoadingScreen, verifyAuthCode } from './store/auth';
import LoadingScreen from './components/Common/LoadingScreen';
import processEntityQRScanned from './functions/processEntityQRScanned';
import { ROUTES } from './constants/routes';
import { enableCard, updateCardAndData } from './store/cards';
import { CARD } from './constants/cards';
import { getMember, loginViaTeams } from './api';
import { addReportProblemTranslationsToStore } from './store/reportProblem';
import useBadgeNotification from './hooks/useBadgeNotification';
import processBoothScanned from './functions/processBoothScanned';
import ThemeEngine from './components/Page/ThemeEngine';
import i18n from './i18n';

const forceSWupdate = () => {
	if ('serviceWorker' in navigator) {
		console.log('forceswupdate');
		navigator.serviceWorker.getRegistrations().then(function (registrations) {
			for (let registration of registrations) {
				registration.update();
			}
		});
	}
};

function App(props) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const userData = useSelector((state) => state.auth.data.userData);
	const officesInStore = useSelector((state) => state.auth.data?.offices);
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const authCode = urlParams.get('code');
	const teakEntityIdentifier = urlParams.get('identifier');
	const paymentRedirectStatus = urlParams.get('redirect_status');
	const paymentIntent = urlParams.get('payment_intent');
	const boothIdentifier = urlParams.get('booth');
	const bookingId = urlParams.get('bookingId');
	const samlMemberID = urlParams.get('memberID');
	const samlOrganization = urlParams.get('organization');

	const isSuccessPaymentRedirect = window.location.pathname === ROUTES.SUCCESS_PAYMENT_REDIRECT;
	const showLoadingScreen = useSelector((state) => state.auth.ui.showLoadingScreen);
	const calendarFormatMonFirst = useSelector((state) => state.auth.data.calendarFormatMonFirst);
	const selectedOffice = useSelector((state) => state.auth.data.selectedOffice);

	useBadgeNotification();

	window.addEventListener('scroll', (e) => {
		e.preventDefault();
		window.scrollTo(0, 0);
	});

	const loginThroughMicrosoftTeams = () => {
		// After receiving the teams token, login via this token at spacestation
		const successLogin = async (result) => {
			const loginResponse = await loginViaTeams(result);
			if (loginResponse.data.member) {
				window.localStorage.setItem('memberID', loginResponse.data.member);
				window.localStorage.setItem('loggedIn', 'true');
			}
			dispatch(enableLoadingScreen(false));
			window.location.assign(ROUTES.DEFAULT);
		};
		// If we face an error during teams login, show error page with option to try again.
		const errorLogin = (error) => {
			console.error('Failure Auth: ' + error);
			dispatch(enableLoadingScreen(false));
			history.push(ROUTES.ERROR_TEAMS_LOGIN);
		};

		// Try to initialize Teams app...if successful, log in via Teams token
		// Otherwise show error page with option to try again.
		try {
			app.initialize()
				.then(() => {
					authentication.initialize();
					authentication.getAuthToken().then(successLogin).catch(errorLogin);
				})
				.catch(errorLogin);
		} catch (err) {
			errorLogin(err);
		}
	};
	const loginThroughSAML = () => {
		// login to SAML via taking params form URL
		window.localStorage.setItem('memberID', samlMemberID);
		window.localStorage.setItem('organization', samlOrganization);

		dispatch(enableLoadingScreen(false));
		window.location.assign(ROUTES.DEFAULT);

		// If we face an error during SAML login, show error page with option to try again.
		const errorLogin = (error) => {
			console.error('Failure Auth: ' + error);
			dispatch(enableLoadingScreen(false));
			history.push(ROUTES.ERROR_TEAMS_LOGIN);
		};
	};

	const checkIfUserIsLoggedIn = async () => {
		const memberId = localStorage.getItem('memberID');
		const strategy = window.localStorage.getItem('loginStrategy');

		//if smal memberID is found, the user was logged in through SAML
		if (samlMemberID) {
			loginThroughSAML();
			return;
		}
		// if no memberId is found, the user is not logged in.
		if (!memberId) {
			if (props.pageContent === ROUTES.TEAMS) {
				loginThroughMicrosoftTeams();
				return;
			}

			if (!authCode) {
				dispatch(enableLoadingScreen(false));
				if (window.location.pathname !== ROUTES.LOGIN) window.localStorage.setItem('redirectUriLogin', window.location);
				history.push(ROUTES.LOGIN + queryString);
				return;
			} else {
				// if authCode coming from Microsoft login callback is found, try to verify the code.
				if (strategy === null) {
					dispatch(enableLoadingScreen(false));
					history.push(ROUTES.LOGIN + queryString);
					return;
				} else {
					const organization = window.localStorage.getItem('organization');
					if (!organization) {
						dispatch(enableLoadingScreen(false));
						history.push(ROUTES.LOGIN + queryString);
						return;
					}
					dispatch(verifyAuthCode(queryString, strategy, organization));
					return;
				}
			}
		}

		try {
			const memberResponse = await getMember(memberId);
			dispatch(addUserData(memberResponse.data));
			if (props.pageContent === ROUTES.LOGIN || props.pageContent === ROUTES.TEAMS) {
				history.push(ROUTES.DEFAULT);
			} else if (props.pageContent === ROUTES.BOOKING) {
				history.push(ROUTES.SEE_DETAILS_OF_BOOKING, { bookingId });
			}
		} catch (error) {
			console.log('error: ', error);
			localStorage.removeItem('memberID');
			if (props.pageContent === ROUTES.TEAMS) {
				loginThroughMicrosoftTeams();
				return;
			}
			dispatch(enableLoadingScreen(false));
			history.push(ROUTES.LOGIN + queryString);
		}
	};

	useEffect(() => {
		if (!userData) {
			checkIfUserIsLoggedIn();
			return;
		}

		const memberId = userData._id;
		const strategy = window.localStorage.getItem('loginStrategy');

		if (strategy && strategy.toLowerCase() === 'otp') {
			const isProfileUpdated = localStorage.getItem('profile_completion_visited');
			if (isProfileUpdated === 'true') {
				history.push(ROUTES.OFFICE_SELECTION);
			} else {
				history.push(ROUTES.PROFILE_COMPLETION);
			}
			dispatch(enableLoadingScreen(false));
			return;
		}

		dispatch(addAuthData(userData, history));

		let newVersion = 'V1.0.45';
		let currentVersion = localStorage.getItem('currentVersion');

		if (currentVersion !== newVersion) {
			localStorage.setItem('currentVersion', newVersion);
			localStorage.setItem('lastVersionUpdate', DateTime.now().toUTC().toLocaleString(DateTime.DATETIME_FULL));
			forceSWupdate();
		}

		if (isSuccessPaymentRedirect && paymentRedirectStatus === 'succeeded') {
			const entityId = window.localStorage.getItem('paymentIntentEntityID');
			const startTime = window.localStorage.getItem('paymentIntentBookingStartTime');
			const endTime = window.localStorage.getItem('paymentIntentBookingEndTime');
			const amount = window.localStorage.getItem('paymentAmount');
			dispatch(enableCard(true));
			dispatch(
				updateCardAndData(CARD.PAYMENT_REDIRECT, {
					amount,
					entityId,
					startTime,
					endTime,
					paymentIntent,
				}),
			);
		}

		if (teakEntityIdentifier !== null && memberId !== null) {
			processEntityQRScanned(dispatch, teakEntityIdentifier, officesInStore, userData, history);
		}

		dispatch(addFeatureTranslationsToStore());
		dispatch(addReportProblemTranslationsToStore());
	}, [userData]);

	useEffect(() => {
		if (boothIdentifier) {
			processBoothScanned(dispatch, boothIdentifier, t);
		}
	}, []);

	// useEffect(() => {
	// 	if (selectedOffice?.cedar?.enabled) {
	// 		SocketIoService.connect(process.env.REACT_APP_ENGINE_HOST_URL);
	// 	} else {
	// 		SocketIoService.disconnect();
	// 	}
	// }, [selectedOffice])

	useEffect(() => {
		if (i18n.language) {
			setLocale(i18n.language);
		}	
	}, [i18n.language]);
	
	const setLocale = (language) => {
		if (language === 'ru') {
			locale('fr');
			return;
		}
		if (language === 'fr') {
			locale('fr-ca');
			return;
		}
		locale(language);
	};

	return <>
		{showLoadingScreen ?
			<LoadingScreen /> : <Page pageContent={props.pageContent}>{props.children}</Page>
		}

		<ThemeEngine></ThemeEngine>
	</>;
}

export default App;
